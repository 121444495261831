@value colors: "../style/colors.css";
@value color-gray-light, color-text-subtle from colors;

.toast {
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
  background: white;
  border-radius: 5px;
  min-width: 250px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  border: 1px solid color-gray-light;
}

.toastContainer {
  position: fixed;
  max-height: calc(100vh - 24px);
  bottom: 12px;
  right: 32px;
  z-index: 50;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 33%;
  scrollbar-color: rgb(0 0 0 / 10%) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.toastContainer::-webkit-scrollbar {
  width: 5px;  /* for vertical scrollbars */
}

.toastContainer::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 10%);
  border-radius: 5px;
}

.toastContainer .toast + .toast {
  margin-top: 16px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.description {
  color: color-text-subtle;
  margin: 0;
}

.button {
  color: color-text-subtle;
}

.button .icon {
  font-size: 18px;
}

.time {
  height: 4px;
  border-radius: 5px 5px 0 0;
}

.hideToast {
  transition: transform 0.6s ease-in-out;
  animation: toast-out-right 0.7s;
  opacity: 0;
}

.textContainer {
  margin: 0 16px;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes toast-out-right {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
