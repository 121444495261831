@value colors: "../../components/library/style/colors.css";
@value color-gray-border, color-text-subtle from colors;

.header {
  border-bottom: 1px solid color-gray-border;
  padding: 16px;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
}

.description {
  color: color-text-subtle;
  margin-top: 4px;
  margin-bottom: 0;
}

.grid {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr 1fr 1.5fr 1fr 0.5fr 1.5fr 1fr 1fr 1fr;
  align-items: flex-start;
  gap: 8px;
}

.row:nth-child(odd) {
  background-color: #f5f7fb;
}
