@value colors: "../library/style/colors.css";
@value color-purple from colors;

.copy {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  text-align: left;
  color: color-purple;
  padding: 0;
}
