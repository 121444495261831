@value colors: "../../../../../components/library/style/colors.css";
@value color-gray-border, color-gray-dark, color-text-subtle from colors;
@value color-purple, color-purple-dark, color-gray, color-error from colors;

.toolbar {
  border-top: 1px solid color-gray-border;
  border-bottom: 1px solid color-gray-border;
  padding: 8px 0;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttongroup {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.input {
  min-width: 320px;
}

.icon {
  color: color-text-subtle;
  font-size: 18px;
}

.buttonicon {
  margin-right: 4px;
}

.active {
  color: color-purple;
}

.active:hover {
  color: color-purple-dark;
}

.inactive {
  color: color-text-subtle;
}

.inactive:hover {
  color: color-gray-dark;
}

.expand {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: unset;
  width: 42px;
  height: 42px;
}

.expand svg {
  padding: 0;
}

.item {
  padding: 8px;
  min-width: 285px;
  min-height: 32px;
  height: auto;
}

.item span {
  display: flex;
  align-items: flex-start;
}

.item span svg {
  margin-right: 8px;
  margin-top: 3px;
}

.item.error span,
.item.error svg {
  color: color-error;
}

.input-tool {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.filters {
  font-weight: 500;
  font-size: 14px;
}
