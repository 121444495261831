@value colors: "../library/style/colors.css";
@value color-purple, color-text-subtle, color-gray-border from colors;

.container {
  padding: 4px 0;
  border-bottom: 1px solid #e9e9e9;
}

.navigation {
  align-items: center;
  gap: 24px;
}

.toolbar {
  align-items: center;
  justify-content: space-between;
}

.content {
  align-items: flex-end;
}

.logo {
  font-family: "Fjalla one", sans-serif;
  font-size: 18px;
  line-height: 18px;
  margin: 0;
}

.point {
  display: block;
  background-color: color-purple;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin: 4px 2px;
}

.soft {
  font-family: Raleway, sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 16px;
}

.link {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: color-text-subtle;
}

.active {
  color: #3042a7;
  background-color: #f0f1fb;
  padding: 4px 8px;
  border-radius: 4px;
}

.searchbar {
  display: flex;
  align-items: center;
  border: 1px solid color-gray-border;
  border-radius: 32px;
  height: 32px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;
  transition: 0.3s;
}

.searchbar:hover,
.searchbar:focus-within {
  border-color: color-purple;
}

.searchbar input {
  border: none;
  outline: none;
  font-family: Roboto, sans-serif;
  font-size: 16px;
}

.icon {
  color: color-text-subtle;
  font-size: 16px;
}
