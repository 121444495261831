@value colors: "../style/colors.css";
@value color-purple, color-text-subtle from colors;

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  transition: 0.3s;
  white-space: nowrap;
}

.custom {
  min-width: 200px;
  min-height: 32px;
}

button:hover {
  border-color: color-purple;
  cursor: pointer;
}

.disabled:hover {
  border-color: #e5e5e5;
}

.icon {
  padding-left: 24px;
  font-size: 16px;
  color: color-text-subtle;
}

.modal {
  position: absolute;
  min-width: 100%;
  box-sizing: border-box;
  background-color: white;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  overflow: hidden;
  margin-top: 4px;
  z-index: 10;
  right: 0;
}

.dropdown {
  position: relative;
}

.center {
  display: flex;
  align-items: center;
}
