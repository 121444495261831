@value colors: "../library/style/colors.css";
@value color-gray-border, color-error, color-success-dark, color-text-subtle from colors;

.background {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(245 247 251 / 65%);
}

.modal {
  background-color: white;
  border-radius: 5px;
  max-width: 90%;
  min-width: 320px;
  max-height: 90vh;
  overflow: auto;
  box-shadow: 0 4px 20px rgb(0 0 0 / 5%);
  scrollbar-color: rgb(0 0 0 / 10%) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
  width: 4px;  /* for vertical scrollbars */
}

.modal::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 10%);
  border-radius: 4px;
}
