@value colors: "../../../../../../components/library/style/colors.css";
@value color-gray-border, color-text-subtle from colors;

.title {
  margin: 0;
  padding: 8px;
  border: 1px solid color-gray-border;
  border-top: 0;
  border-right: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: color-text-subtle;
}
