@value colors: "../../../../../../components/library/style/colors.css";
@value color-gray-border, color-purple, color-purple-dark, color-error, color-success-dark, color-text-subtle from colors;

.header,
.main,
.footer {
  padding: 16px;
  border-bottom: 1px solid color-gray-border;
}

.header p {
  margin: 0;
}

.header b,
.main b {
  margin-right: 4px;
}

.body {
  border: 1px solid color-gray-border;
  padding: 8px;
  border-radius: 4px;
  max-height: 50vh;
  overflow-y: scroll;
  scrollbar-color: rgb(0 0 0 / 10%) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.body::-webkit-scrollbar {
  width: 5px;  /* for vertical scrollbars */
}

.body::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 10%);
  border-radius: 5px;
}

.input {
  border: 1px solid color-gray-border;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 5px;
  min-width: 800px;
  min-height: 250px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
}

.icon {
  margin-right: 4px;
}

.hint {
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 12px;
}

.hint.fail {
  color: color-error;
}

.hint.success {
  display: flex;
  align-items: center;
  gap: 4px;
  color: color-success-dark;
}

.hint span,
.hint svg {
  font-size: 12px;
}

.email {
  min-width: 320px;
}

.preview-footer {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.button {
  height: 32px;
}

.details {
  color: color-text-subtle;
  margin-left: 4px;
}

.tag {
  background-color: color-purple;
  color: white;
  border-radius: 2rem;
  padding: 0.25rem 1rem;
  transition: 0.3s;
}

.tag-list {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.tag:hover {
  background-color: color-purple-dark;
}
