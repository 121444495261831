@value colors: "../library/style/colors.css";
@value color-gray-border, color-text-subtle from colors;

.header,
.main,
.footer {
  padding: 16px;
}

.header {
  border-bottom: 1px solid color-gray-border;
}

.header p {
  margin: 0;
  font-weight: 500;
}

.details {
  color: color-text-subtle;
  margin-left: 4px;
}

.main {
  min-width: 320px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  border-top: 1px solid color-gray-border;
}
