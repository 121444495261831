.icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title {
  margin: 0;
  font-weight: 500;
  font-size: 1.5rem;
}

.list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
}
