@value colors: "components/library/style/colors.css";
@value color-purple-bg, color-purple-dark from colors;

.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.form-textarea {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.form,
.form-textarea {
  column-gap: 2rem;
  row-gap: 1rem;
}

.form label,
.form-textarea label {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 500;
  margin-top: 0;
}

.form input,
.form-textarea textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.button {
  background-color: color-purple-bg;
  color: color-purple-dark;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  transition: 0.3s;
}

.icon {
  font-size: 1rem;
}

.button:hover {
  background-color: color-purple-dark;
  color: color-purple-bg;
}
