@value colors: "../style/colors.css";
@value color-text-subtle, color-purple, color-purple-bg from colors;

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 42px;
  padding: 0 8px;
}

.label {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: color-text-subtle;
}

.item svg {
  color: color-purple;
  font-size: 16px !important;
}

.item:hover {
  background-color: color-purple-bg;
}
