@value colors: "components/library/style/colors.css";
@value color-border, color-success from colors;

.sender {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.75rem;
}

.sender label {
  display: flex;
  flex-direction: column;
}

.sender input {
  padding: 0.5rem;
  border: 1px solid color-border;
  border-radius: 0.25rem;
  height: 42px;
  box-sizing: border-box;
}

.icon-container {
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  color: color-success;
}
