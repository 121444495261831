@value colors: "components/library/style/colors.css";
@value color-gray-border, color-gray-dark, color-text-subtle, color-purple from colors;
@value color-purple-dark, color-gray, color-error from colors;

.template-container {
  position: relative;
  width: 33%;
}

.label {
  margin: 0 0 4px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
}

.select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select-empty {
  align-items: center;
}

.select-full {
  align-items: flex-start;
}

.select-all {
  margin-right: 0.5rem;
}

.select-template {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.input {
  border: 1px solid color-gray-border;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  max-width: 450px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: color-text-subtle;
}

.border {
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
}

.border:hover {
  border-color: color-gray-border;
}

.modal {
  width: 100%;
  max-width: 450px;
  box-sizing: border-box;
  position: absolute;
  background-color: white;
  border: 1px solid color-gray-border;
}

.template,
.template-selected {
  display: flex;
  flex-direction: column;
  padding: 8px;
  overflow-y: scroll;
  scrollbar-color: rgb(0 0 0 / 10%) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.template::-webkit-scrollbar,
.template-selected::-webkit-scrollbar {
  width: 5px;  /* for vertical scrollbars */
}

.template::-webkit-scrollbar-thumb,
.template-selected::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 10%);
  border-radius: 5px;
}

.sub-label {
  color: color-text-subtle;
  font-weight: 500;
  margin: 0;
  padding: 8px 8px 0;
}

.checkbox input {
  margin-right: 8px;
}

.search {
  width: 100%;
  min-height: 42px;
  padding: 8px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid color-gray-border;
  font-family: Roboto, sans-serif;
}

.template-email {
  background-color: #f5f7fb;
  padding: 0 16px;
  border-radius: 16px;
  font-size: 14px;
}

.group {
  display: flex;
  align-items: center;
}

.custom {
  height: unset;
  min-height: unset;
  margin-right: 8px;
}

.divider {
  border: none;
  border-top: 1px solid color-gray-border;
}
