@value colors: "../library/style/colors.css";
@value color-gray-border, color-purple-bg, color-purple, color-text-subtle from colors;

.section {
  padding: 16px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border: 1px solid color-gray-border;
  border-bottom: 0;
  border-left: 0;
}

.title {
  font-weight: 500;
  margin-top: 0;
}

.days {
  padding: 8px;
  margin: 0;
  font-weight: 500;
  border-bottom: 1px solid color-gray-border;
}

.days:first-child {
  border-left: 1px solid color-gray-border;
}

.days + .days {
  border-left: 1px solid color-gray-border;
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
}

.icon {
  margin-right: 4px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.month {
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  text-transform: capitalize;
}

.nav {
  background-color: color-purple-bg;
  border-radius: 32px;
  padding: 0;
  width: 32px;
  height: 32px;
  min-height: unset;
}

.arrow {
  color: color-purple;
}

.nav:hover .arrow {
  color: white;
}

.email-list {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  align-items: center;
}
