@value colors: "../../../components/library/style/colors.css";
@value color-gray-border, color-purple from colors;

.header,
.main {
  max-width: 700px;
  padding: 1rem;
}

.header {
  border-bottom: 1px solid color-gray-border;
}

.name {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
  color: color-purple;
}

.title {
  margin: 4px 0 0;
}

.main {
  max-height: 80vh;
  overflow: scroll;
  scrollbar-color: rgb(0 0 0 / 10%) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.main::-webkit-scrollbar {
  width: 1rem;  /* for vertical scrollbars */
}
