@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;900&family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");

button,
.button {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 18px;
}

h1,
.h1 {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 48px;
}

h2,
.h2 {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 36px;
}

h3,
.h3 {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 30px;
}

h4,
.h4 {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 24px;
}

h5,
.h5 {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 20px;
}

h6,
.h6 {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 18px;
}

a,
p,
span,
label,
.body-1,
.p {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 150%;
}

b,
.bold {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

a,
.link {
  background: none;
  border: none;
  color: #526af2;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-decoration: underline;
}

a:hover,
.link:hover {
  color: #404fa1;
}

.body-2 {
  font-family: Roboto, sans-serif;
  font-size: 16px;
}

.caption {
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.subtitle-1 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  text-transform: uppercase;
}

.subtitle-2 {
  font-family: Raleway, sans-serif;
  font-size: 16px;
}

@media screen and (max-width: 480px) {
  button,
  .button {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 18px;
  }

  h1,
  .h1 {
    font-family: Raleway, sans-serif;
    font-weight: 700;
    font-size: 40px;
  }

  h2,
  .h2 {
    font-family: Raleway, sans-serif;
    font-weight: 700;
    font-size: 36px;
  }

  h3,
  .h3 {
    font-family: Raleway, sans-serif;
    font-weight: 700;
    font-size: 30px;
  }

  h4,
  .h4 {
    font-family: Raleway, sans-serif;
    font-weight: 700;
    font-size: 20px;
  }

  h5,
  .h5 {
    font-family: Raleway, sans-serif;
    font-weight: 700;
    font-size: 18px;
  }

  h6,
  .h6 {
    font-family: Raleway, sans-serif;
    font-weight: 700;
    font-size: 16px;
  }

  a,
  p,
  span,
  label,
  .body-1,
  .p {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 150%;
  }

  b,
  .bold {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 700;
  }

  a,
  .link {
    background: none;
    border: none;
    color: #526af2;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
  }

  a:hover,
  .link:hover {
    color: #404fa1;
  }

  .body-2 {
    font-family: Roboto, sans-serif;
    font-size: 14px;
  }

  .caption {
    font-family: Roboto, sans-serif;
    font-size: 13px;
  }

  .subtitle-2 {
    font-family: Raleway, sans-serif;
    font-size: 16px;
  }
}
