@value colors: "../../components/library/style/colors.css";
@value color-purple, color-gray-border, color-text-subtle, color-error, color-success-dark from colors;

.grid-title {
  border-bottom: 1px solid color-gray-border;
  padding: 8px;
}

.grid-title p {
  font-weight: 500;
  margin: 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.task:nth-child(odd) {
  background-color: #f5f7fb;
}

.error,
.waiting {
  color: color-error;
  font-weight: 500;
}

.success {
  color: color-success-dark;
  font-weight: 500;
}

.actions {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.actions button {
  transition: 0.3ms;
}

.actions button:hover {
  color: color-purple;
}
