@value colors: "../style/colors.css";
@value color-error from colors;

.error > div,
.fail > div {
  color: color-error;
  border-color: color-error;
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
