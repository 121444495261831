@value colors: "components/library/style/colors.css";
@value color-purple-bg, color-purple-dark, color-gray-border from colors;
@value color-text-subtle, color-error, color-success-dark from colors;

.grid-title-container {
  border-bottom: 1px solid color-gray-border;
  padding: 8px;
}

.grid,
.grid-content {
  display: grid;
  grid-template-columns: 0.25fr 2fr 0.5fr 0.5fr 3fr 0.5fr 1fr 1fr;
  grid-column-gap: 16px;
}

.grid p {
  margin: 0;
  font-weight: 500;
}

.grid-row:nth-child(even) {
  background-color: color-purple-bg;
}

.button-group {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: flex-end;
}

.searchbar {
  margin: 0;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
}

.type {
  font-size: 1rem;
  font-weight: 500;
  color: color-text-subtle;
  padding: 0.5rem 1rem;
  transition: 0.3s;
  border-radius: 0.25rem;
}

.selected {
  background-color: color-purple-bg;
  color: color-purple-dark;
}
