.mail {
  width: 100%;
  background-color: #fd7726;
  color: white;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px;
  border-radius: 4px;
  opacity: 0.5;
  max-width: 150px;
}

.mail span,
.event span {
  font-size: 10px;
}
