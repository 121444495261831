@value colors: "./colors.css";
@value color-purple, color-gray-border, color-text-subtle, color-error, color-success-dark from colors;

.header {
  border-bottom: 1px solid color-gray-border;
  padding: 16px;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
}

.description {
  color: color-text-subtle;
  margin-top: 4px;
  margin-bottom: 0;
}

.row {
  display: flex;
  flex-direction: row;
}

.container {
  max-width: 1440px;
  width: 90%;
  margin: auto;
}

.section {
  padding: 24px;
}

@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
