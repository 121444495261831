@value colors: "../library/style/colors.css";
@value color-gray-border, color-success, color-error from colors;

.header {
  padding: 16px;
  border-bottom: 1px solid color-gray-border;
}

.header p {
  margin: 0;
  font-weight: 500;
}

.main,
.container {
  overflow-x: scroll;
  padding: 16px;
  scrollbar-color: rgb(0 0 0 / 10%) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.main::-webkit-scrollbar,
.container::-webkit-scrollbar {
  height: 5px;  /* for vertical scrollbars */
}

.main::-webkit-scrollbar-thumb,
.container::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 10%);
  border-radius: 5px;
}

.container {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.legend {
  font-size: 14px;
  font-weight: 500;
  margin-right: 4px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  border: 1px solid color-gray-border;
  border-bottom: 0;
  border-right: 0;
  margin-bottom: 4px;
  min-width: 1250px;
}

.grid p {
  margin: 0;
  padding: 8px;
  border: 1px solid color-gray-border;
  border-top: 0;
  border-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bold {
  font-weight: 500;
}

.footer {
  padding: 16px;
  border-top: 1px solid color-gray-border;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.input {
  border: 1px solid color-gray-border;
  border-radius: 4px;
  padding: 8px;
  font-family: Roboto, sans-serif;
}

.result {
  font-size: 0.75rem;
}
