@value colors: "components/library/style/colors.css";
@value color-text-subtle, color-purple, color-purple-dark, color-error, color-success, color-warning-dark from colors;

.row {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr 1fr 1.5fr 1fr 0.5fr 1.5fr 1fr 1fr 1fr;
  align-items: center;
  gap: 8px;
  min-height: 32px;
}

.outsiderow {
  padding: 8px 0;
}

.outsiderow:nth-child(odd) {
  background-color: #f5f7fb;
}

.group {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.grid p {
  margin: 0;
  font-size: 14px;
}

.checkbox {
  margin-top: 4px;
}

.buttongroup {
  display: flex;
  gap: 8px;
}

.action {
  min-width: unset;
  min-height: unset;
  color: color-text-subtle;
  transition: 0.3s;
}

.action:hover {
  color: color-purple;
}

.link {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: color-purple;
  text-decoration: underline;
  padding: 0;
}

.link:hover {
  color: color-purple-dark;
}

.pill {
  width: max-content;
  padding: 0 16px;
  border-radius: 16px;
  color: black;
  text-transform: capitalize;
}

.draft,
.waiting_schedule {
  background-color: color-error;
  color: white;
}

.send,
.sending {
  background-color: color-success;
  color: white;
}

.schedule {
  background-color: color-warning-dark;
  color: white;
}
