.scaleUpVerTop {
  animation: scaleUpVerTop 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scaleUpVerTop 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scaleUpVerTop {
  0% {
    opacity: 0;
    transform: scaleY(0);
    transform: scaleY(0);
    transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    transform: scaleY(1);
    transform: scaleY(1);
    transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes scaleUpVerTop {
  0% {
    opacity: 0;
    transform: scaleY(0);
    transform: scaleY(0);
    transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    transform: scaleY(1);
    transform: scaleY(1);
    transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

.bounceIn {
  opacity: 0;
  animation-name: bounceIn;
  animation-duration: 450ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }

  80% {
    opacity: 1;
    transform: scale(0.89);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

.fadeIn {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 450ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.fadeInFast {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 100ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeOut {
  opacity: 1;
  animation-name: fadeOut;
  animation-duration: 450ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.fadeOutFast {
  opacity: 1;
  animation-name: fadeOut;
  animation-duration: 225ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.fadeOutButton {
  opacity: 1;
  animation-name: fadeOutButton;
  animation-duration: 450ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fadeOutButton {
  0% {
    opacity: 1;
    max-height: 48px;
  }

  50% {
    opacity: 0.5;
    max-height: 24px;
  }

  100% {
    opacity: 0;
    max-height: 0;
  }
}

.slideInTop {
  opacity: 0;
  animation-name: slideInTop;
  animation-duration: 450ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes slideInTop {
  0% {
    opacity: 0;
    transform: translateY(-600px);
  }

  50% {
    opacity: 0.5;
    transform: translateY(-300px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slideInRight {
  opacity: 0;
  animation-name: slideInRight;
  animation-duration: 450ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(150%);
  }

  50% {
    opacity: 0.5;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.spinner {
  animation: spin 1s linear infinite;
  border: 3px solid rgb(255 255 255 / 50%);
  border-top: 3px solid #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner.purple {
  border: 4px solid rgb(82 106 241 / 10%);
  border-top: 4px solid #526af2;
}
