@value colors: "components/library/style/colors.css";
@value color-text-subtle, color-purple, color-purple-dark from colors;

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.title {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

.description {
  color: color-text-subtle;
  margin-top: 4px;
  margin-bottom: 0;
}

.button {
  background-color: color-purple;
  height: 42px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 4px;
  transition: 0.3s;
}

.button:hover {
  color: white;
  background-color: color-purple-dark;
}

.task {
  background-color: #f5f7fb;
  padding: 16px;
  border-radius: 4px;
  width: max-content;
  margin-top: 16px;
  margin-bottom: 16px;
}

.task-title {
  margin: 0;
  font-weight: 500;
}

.contacts {
  font-weight: 500;
  margin: 0;
}

.project p,
.project b {
  font-size: 14px;
}

.project b {
  margin-right: 0.25rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
