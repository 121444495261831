@value colors: "components/library/style/colors.css";
@value color-gray-border, color-gray-dark, color-text-subtle, color-purple from colors;
@value color-purple-dark, color-gray, color-error from colors;

.header {
  padding: 16px 0;
  border-bottom: 1px solid color-gray-border;
}

.back {
  min-height: 0;
  margin-bottom: 4px;
}

.icon {
  font-size: 16px;
  margin-right: 4px;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
}

.main {
  padding: 24px 0;
  background-color: #f5f7fb;
}

.form {
  background: white;
  border-radius: 4px;
  padding: 24px;
}

.submit {
  margin-top: 16px;
  margin-right: 0;
  margin-left: auto;
}

.users,
.template,
.segments {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 0 0 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid color-gray-border;
  font-weight: 500;
  font-size: 18px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.checkbox {
  margin-right: 8px;
}

.template-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
}

.select-container {
  display: flex;
  flex-direction: column;
}

.input {
  border: 1px solid color-gray-border;
  padding: 8px;
  border-radius: 5px;
  margin-top: 4px;
  max-width: 450px;
  font-family: Roboto, sans-serif;
  text-transform: capitalize;
}

.inactive {
  text-decoration: line-through;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255 255 255 / 75%);
}

.modal {
  background-color: white;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1rem rgb(0 0 0 / 10%);
}

.modal > div {
  height: unset;
}

.hint {
  color: rgb(0 0 0 / 50%);
}
