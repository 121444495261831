@value colors: "../../../../../../components/library/style/colors.css";
@value color-gray-border, color-gray, color-text-subtle, color-error, color-purple from colors;

.date {
  padding: 8px;
  min-height: 100px;
  border: 1px solid color-gray-border;
  border-top: 0;
  border-right: 0;
}

.today {
  border: 2px solid color-purple;
}

.day {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: color-text-subtle;
  margin: 0;
}

.past {
  color: color-gray;
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.event,
.mail {
  color: white;
  font-weight: 400;
  text-transform: capitalize;
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
}

.event {
  background-color: color-error;
}

.mail {
  background-color: color-purple;
}
