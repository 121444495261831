@value colors: "../library/style/colors.css";
@value color-gray-border, color-success, color-error from colors;

.header {
  padding: 16px;
  border-bottom: 1px solid color-gray-border;
}

.title {
  font-weight: 500;
  margin: auto;
}

.main {
  padding: 16px;
  max-height: 70vh;
  overflow-y: scroll;
  scrollbar-color: rgb(0 0 0 / 10%) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.main::-webkit-scrollbar {
  width: 5px;  /* for vertical scrollbars */
}

.main::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 10%);
  border-radius: 5px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border: 1px solid color-gray-border;
  border-bottom: 0;
  border-right: 0;
}

.grid p,
.grid button {
  margin: 0;
  padding: 8px;
  border: 1px solid color-gray-border;
  border-top: 0;
  border-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bold {
  font-weight: 500;
}

.success {
  color: color-success;
}

.error {
  color: color-error;
}

.footer {
  padding: 16px;
  border-top: 1px solid color-gray-border;
  display: flex;
  justify-content: flex-end;
}

.close {
  height: 32px;
  min-height: unset;
  color: #3042a7;
  background-color: #f0f1fb;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
}

.close:hover {
  background-color: #ededff;
}
