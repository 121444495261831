@value colors: "../../library/style/colors.css";
@value color-gray-border, color-error, color-purple, color-success-dark, color-text-subtle from colors;

.day {
  padding: 8px;
  border-bottom: 1px solid color-gray-border;
  border-left: 1px solid color-gray-border;
  width: 125px;
  height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgb(0 0 0 / 10%) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.day::-webkit-scrollbar {
  height: 5px;  /* for vertical scrollbars */
}

.day::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 10%);
  border-radius: 5px;
}

.today {
  border: 2px solid color-purple;
}

.date {
  margin: 0;
  font-weight: 500;
  color: color-text-subtle;
  transition: 0.3s;
}

.event,
.mail {
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}

.event {
  background-color: color-error;
}

.mail {
  background-color: color-purple;
}

.success {
  background-color: #ecf0ff;
}

.error {
  background-color: #ffb6b6;
}
