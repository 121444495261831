@value colors: "../../../../../../components/library/style/colors.css";
@value color-gray-border, color-purple, color-purple-bg, color-text-subtle, color-purple-light from colors;

.toolbar {
  border-bottom: 1px solid color-gray-border;
}

.row {
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  width: 150px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 8px 0 0;
}

.navigation {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  min-height: unset;
  padding: 0;
  background-color: color-purple-bg;
  color: color-text-subtle;
  transition: 0.3s;
}

.navigation + .navigation {
  margin-left: 8px;
}

.navigation:hover {
  color: color-purple;
  background-color: #f0f1fb;
}

.buttongroup {
  display: flex;
  flex-direction: row;
}
