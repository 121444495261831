@value colors: "../../../components/library/style/colors.css";
@value color-purple, color-gray, color-text-subtle from colors;

.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
}

.logo {
  display: flex;
  align-items: flex-end;
  margin-bottom: 48px;
}

.ayomi {
  font-family: "Fjalla One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 55px;
  line-height: 40px;
  text-transform: uppercase;
  margin: 0;
}

.point {
  width: 12px;
  height: 12px;
  margin: 0 4px;
  border-radius: 12px;
  background-color: color-purple;
}

.campaign {
  margin: 0;
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 18px;
}

.searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  height: 42px;
  max-width: 640px;
  width: 90%;
  border-radius: 42px;
  padding: 0 16px;
  border: 1px solid color-gray;
}

.icon {
  color: color-text-subtle;
}

.input {
  border: none;
  outline: none;
  width: 100%;
  font-family: Roboto, sans-serif;
}
