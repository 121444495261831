@value colors: "../../../components/library/style/colors.css";
@value color-gray-border, color-purple, color-error, color-purple-dark from colors;

.title {
  font-weight: 500;
  font-size: 1.25rem;
  font-family: Roboto, sans-serif;
}

.textarea {
  border: 1px solid color-gray-border;
  border-radius: 5px;
  min-height: 48px;
  font-family: Roboto, sans-serif;
  padding: 8px;
}

.textarea:focus,
.textarea:hover {
  border-color: color-purple;
  outline: none;
}

.error {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: color-error;
  font-style: italic;
}

.default {
  display: flex;
  column-gap: 0.25rem;
  margin-bottom: 0.5rem;
}

.tag {
  background-color: color-purple;
  color: white;
  border-radius: 2rem;
  padding: 0.25rem 1rem;
  transition: 0.3s;
}

.tag-list {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.tag:hover {
  background-color: color-purple-dark;
}
