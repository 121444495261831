@value colors: "../library/style/colors.css";
@value color-gray-border, color-text-subtle, color-purple from colors;

.title {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr 1fr 1.5fr 1fr 0.5fr 1.5fr 1fr 1fr 1fr;
  align-items: center;
  gap: 8px;
  min-height: 32px;
}

.container {
  border-bottom: 1px solid color-gray-border;
}

.group {
  display: flex;
  align-items: center;
  gap: 4px;
}

.title p {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}

.info {
  color: color-text-subtle;
  font-size: 14px !important;
  transition: 0.3s;
}

.info:hover {
  color: color-purple;
}

.checkbox {
  margin-bottom: 2px;
}
