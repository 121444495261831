@value colors: "../../../../components/library/style/colors.css";
@value color-gray-border from colors;

.modal-confirmation {
  padding: 24px;
  border-bottom: 1px solid color-gray-border;
}

.modal-confirmation p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.modal-footer {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}
